import _ from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Content from '../components/Content';

const Container = styled.div``;
const Description = styled.span`
  margin-left: 2em;
`;

const LinksPage = ({ data }:{data:{allLinksJson:{nodes:Array<LinkPageNode>}}}) => {
  const {
    allLinksJson: { nodes },
  } = data;

  const grouped = {};

  nodes.forEach((node:LinkPageNode) => {
    if (!grouped[node.Group]) {
      grouped[node.Group] = [];
    }
    grouped[node.Group].push(node);
  });

  return (
    <Layout>
      <Helmet>
        <title>Legacy of Steel - Links</title>
      </Helmet>
      <Content>
        <Container>
          <h1>Links</h1>
          {_.map(_.values(grouped), (nodes:Array<LinkPageNode>) => (
            <div>
              <h3>{nodes[0].Group}</h3>
              <ul>
                {_.map(nodes, (node:LinkPageNode) => (
                  <li key={node.Id}>
                    <a href={node.Wayback || node.Url}>{node.Name}</a>
                    <Description>{node.Description}</Description>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Container>
      </Content>
    </Layout>
  );
};

interface LinkPageNode {
  Id:string;
  Description:string;
  Group:string;
  Name:string;
  Url:string;
  Wayback:string;
}

export const query = graphql`
  query LinksPageQuery {
    allLinksJson(sort: { fields: [Group, Name], order: ASC }) {
      nodes {
        Description
        Group
        Name
        Url
        Wayback
        id
      }
    }
  }
`;
export default LinksPage;
